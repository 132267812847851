import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { UserVerifyComponent } from './shared/components/user-verify/user-verify.component';
import { AbzLoginComponent } from './abz-login/abz-login.component';
import { TaggioComponent } from './taggio/taggio.component';
import { config } from '../environments/environment';
import { BlockMobileBrowserComponent } from "./shared/components/block-mobile-browser/block-mobile-browser.component";
const routesEnvConfig = config.ROUTES;
let loginModule: any = { 'path': '', component: LoginComponent, data: { isPublic: true } };
let login1Module: any = { 'path': 'login/:type/:email', component: LoginComponent, data: { isPublic: true } };
let registerModule: any = { 'path': 'register', component: LoginComponent, data: { isPublic: true } };

if (routesEnvConfig.LOGIN_COMPONENT === 'PICKCEL') {
    loginModule.component = LoginComponent;
    login1Module.component = LoginComponent;
}

if (routesEnvConfig.LOGIN_COMPONENT === 'ABZOLUTEDSS') {
    loginModule.component = AbzLoginComponent;
    login1Module.component = AbzLoginComponent;
}

if(routesEnvConfig.LOGIN_COMPONENT === 'TAGGIO') {
    loginModule.component = TaggioComponent;
    login1Module.component = TaggioComponent;
}

const routes: Routes = [
    loginModule,
    login1Module,
    // {
        // path: 'register',
        // loadChildren: './signup/signup.module#SignupModule',
        // data: { isPublic: true }
    // },
    registerModule,
    {
        path: 'index',
        loadChildren: './index/index.module#IndexModule',
    },
    {
        path: 'passwordreset',
        loadChildren: './passwordreset/passwordreset.module#PasswordresetModule',
        data: { isPublic: true }

    },
    {
        path: 'user-verify/:jwt/:UD',
        component: UserVerifyComponent
    }, 
    {
        path: 'unsupported-browser/:view',
        component: BlockMobileBrowserComponent
    }
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            useHash: true,
            enableTracing: false,
            onSameUrlNavigation: 'reload',
            preloadingStrategy: PreloadAllModules,
        })
    ],
    exports: [RouterModule]
})

export class AppRoutingModule { }
